import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingStatusBadge } from 'domains/onboarding/components';
import { TaskIconByType } from 'domains/onboarding/constants';
import {
  getTaskPreselectedText,
  getUrlFromOnboardingKeyType,
} from 'domains/onboarding/utils';
import {
  Avatar,
  CaretRightIcon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
} from 'elements';
import { OnboardingTask } from 'services/constants';
import { getPath } from 'services/utils';

interface Props extends ListItemProps {
  task: OnboardingTask;
  sectionKey: string;
}

const TaskListItem = ({ children, task, sectionKey, ...props }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  const Icon = TaskIconByType[task.type];
  const secondaryText = getTaskPreselectedText(task, t);

  return (
    <ListItem
      sx={{
        py: 2,
        pl: 2,
        '&+&': { mt: 3 },
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.divider,
      }}
      {...props}
      secondaryAction={
        <IconButton
          edge="end"
          component={Link}
          to={{
            pathname: generatePath(
              `${getPath(
                'orgOnboarding'
              )}/${sectionKey}/${getUrlFromOnboardingKeyType(task.type)}/${
                task.id
              }`,
              {
                orgId: organization!.id,
              }
            ),
          }}
        >
          <CaretRightIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          sx={{ bgcolor: (theme) => theme.variables.warmGrey[200] }}
        >
          {Icon && <Icon color="primary" />}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        sx={{ ml: 2 }}
        primary={t(`orgOnboardingTaskTitle.${task.type}`)}
        secondary={secondaryText}
      />

      <OnboardingStatusBadge status={task.status} sx={{ mr: 2 }} />
    </ListItem>
  );
};

export default TaskListItem;
