import { useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import { sortLabelsByAmounts } from 'domains/onboarding/utils';
import {
  Box,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  BalanceSheetTotal,
  OnboardingTaskBalanceSheetTotal,
  OnboardingTaskType,
  TaskPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  balanceSheetTotal: BalanceSheetTotal | '';
}

export interface Props extends TaskPropsBase {
  task: OnboardingTaskBalanceSheetTotal;
  onUpdate: (newTask: OnboardingTaskBalanceSheetTotal) => void;
}

const BalanceSheetTotalTask = ({
  task,
  prevTask,
  nextTask,
  isTaskNavigationEnabled,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    data: { balanceSheetTotal },
  } = task;
  const { useUpdateBalanceSheetTotal } = useTanstackQuery();
  const { mutate: orgBalanceSheetTotalMutate } = useUpdateBalanceSheetTotal({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      balanceSheetTotal: balanceSheetTotal.value ?? '',
    },
    onSubmit: (values) => {
      if (!values.balanceSheetTotal) return;
      orgBalanceSheetTotalMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: {
          balanceSheetTotal: values.balanceSheetTotal,
        },
      });
    },
  });

  const sortedBalanceSheetTotalValues = useMemo(
    () =>
      sortLabelsByAmounts(
        balanceSheetTotal.possibleValues.map((item) => ({
          value: item,
          label: t(`balanceSheetTotalLabels.${item}`),
        }))
      ),
    [balanceSheetTotal.possibleValues]
  );

  return (
    <>
      <PageTitle
        pt={0}
        title={t(
          `orgOnboardingTaskTitle.${OnboardingTaskType.balanceSheetTotal}`
        )}
      />
      <Typography variant="body2" mb={5}>
        {t('orgOnboardingBalanceSheetTotalTask.description', {
          year: moment().year() - 1,
        })}
      </Typography>

      <FormControl
        fullWidth
        disabled={formik.isSubmitting || !balanceSheetTotal.editable}
      >
        <InputLabel id="balance-sheet-total">
          {t('orgOnboardingBalanceSheetTotalTask.balanceSheetTotalLabel')}
        </InputLabel>
        <Select
          displayEmpty
          labelId="balance-sheet-total"
          renderValue={(selected) => {
            if (!selected) return '';
            return t(`balanceSheetTotalLabels.${selected}`);
          }}
          {...formik.getFieldProps('balanceSheetTotal')}
        >
          {sortedBalanceSheetTotalValues.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box mt={4}>
        <OnboardingTaskActions
          prevTask={prevTask}
          nextTask={nextTask}
          isTaskNavigationEnabled={isTaskNavigationEnabled}
          disabled={
            formik.isSubmitting ||
            !formik.dirty ||
            !formik.values.balanceSheetTotal
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default BalanceSheetTotalTask;
