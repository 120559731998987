import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useCountdownTimer, {
  getReadableRemainingTime,
} from 'hooks/useCountdownTimer';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { BiometricAuthRequestEventData } from 'services/memberSse';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const AUTH_REQUEST_TTL_IN_SECONDS = 5 * 60;

interface Props extends DialogProps {
  authRequest: BiometricAuthRequestEventData | null;
  onClose: () => void;
}

const ConfirmPaymentWith3dsBiometricAuthDialog = ({
  authRequest: authRequestNullable,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { remainingTime, startTimer } = useCountdownTimer();
  const authRequest = useMemo(() => authRequestNullable!, []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    startTimer(moment(authRequest.expiresAt).diff(moment(), 'seconds'));
  }, []);

  const pass3dsAuthentication = async (status: 'APPROVED' | 'DECLINED') => {
    try {
      setIsLoading(true);
      await api.pass3dsAuthentication({
        gpsInitiateActionId: authRequest.gpsInitiateActionId,
        pmReferenceId: authRequest.pmReferenceId,
        pubToken: authRequest.publicToken,
        status,
      });
      if (!mounted.current) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('confirmPaymentWith3dsBiometricAuthDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mt={1} mb={3}>
          <Box position="relative">
            <CircularProgress
              size="large"
              color="secondary"
              variant="determinate"
              value={
                remainingTime !== null
                  ? (remainingTime / AUTH_REQUEST_TTL_IN_SECONDS) * 100
                  : 0
              }
              sx={{ display: 'block' }}
            />
            {remainingTime !== null && (
              <Typography
                variant="subtitle2"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  left: 0,
                  transform: 'translateY(-50%)',
                  textAlign: 'center',
                  fontVariantNumeric: 'tabular-nums',
                  color: 'text.secondary',
                }}
              >
                {getReadableRemainingTime(remainingTime)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx={2}
        >
          <Box minWidth={0} mr={2}>
            <Typography variant="subtitle1" noWrap>
              {authRequest.merchant}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {moment(authRequest.timestamp).format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
          <Typography variant="subtitle1">
            <FormatMoney
              value={authRequest.amountInMinorUnits}
              fractionalPart
            />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="error"
          disabled={isLoading}
          onClick={() => pass3dsAuthentication('DECLINED')}
        >
          {t('common.button.decline')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => pass3dsAuthentication('APPROVED')}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(
  ConfirmPaymentWith3dsBiometricAuthDialog
);
