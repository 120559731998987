import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import { Box, Grid, LoaderWithOverlay, TextField } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingTaskRegNumber,
  OnboardingTaskType,
  SupportedCountry,
  TaskPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, trimObjValues } from 'services/utils';

interface FormValues {
  nationalRegisterCourt: string;
  registrationNumber: string;
}

export interface Props extends TaskPropsBase {
  task: OnboardingTaskRegNumber;
  onUpdate: (newTask: OnboardingTaskRegNumber) => void;
}

const RegisteredNumberTask = ({
  task,
  prevTask,
  nextTask,
  isTaskNavigationEnabled,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const { useUpdateRegisteredNumber } = useTanstackQuery();
  const { mutate: orgRegNumberMutate } = useUpdateRegisteredNumber({
    onSuccess: (response) => {
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization!,
            tradeRegisterNumber: response.data.registrationNumber.value || '',
            nationalRegisterCourt:
              response.data.nationalRegisterCourt.value || '',
          },
        },
      });
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const {
    data: { nationalRegisterCourt, registrationNumber },
  } = task;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      nationalRegisterCourt: nationalRegisterCourt.value ?? '',
      registrationNumber: registrationNumber.value ?? '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      // We check for AT or DE on FE, but there might be more
      // validations for nationalRegisterCourt.
      // todo: should we move AT/DE check on BE? Check with Yasen
      if (
        task.data.nationalRegisterCourt.required &&
        !values.nationalRegisterCourt
      ) {
        errors['nationalRegisterCourt'] = t('common.errors.fieldRequired');
      }
      return errors;
    },
    onSubmit: (values) => {
      orgRegNumberMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: trimObjValues(values),
      });
    },
  });

  const hasEmptyField =
    ([SupportedCountry.at, SupportedCountry.de].includes(
      organization!.billingAddress.country
    ) &&
      !formik.values.nationalRegisterCourt.trim()) ||
    !formik.values.registrationNumber.trim();

  return (
    <>
      <PageTitle
        pt={0}
        pb={3}
        title={t(
          `orgOnboardingTaskTitle.${OnboardingTaskType.registeredNumber}`
        )}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled={formik.isSubmitting || !registrationNumber.editable}
            label={t('orgOnboardingRegNumberTask.registrationNumberLabel')}
            placeholder={t(
              'orgOnboardingRegNumberTask.registrationNumberPlaceholder'
            )}
            {...formik.getFieldProps('registrationNumber')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={formik.isSubmitting || !nationalRegisterCourt.editable}
            label={t('orgOnboardingRegNumberTask.nationalRegisterCourtLabel')}
            placeholder={t(
              'orgOnboardingRegNumberTask.nationalRegisterCourtPlaceholder'
            )}
            {...formik.getFieldProps('nationalRegisterCourt')}
            error={!!formik.errors.nationalRegisterCourt}
            helperText={formik.errors.nationalRegisterCourt}
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <OnboardingTaskActions
          prevTask={prevTask}
          nextTask={nextTask}
          isTaskNavigationEnabled={isTaskNavigationEnabled}
          disabled={formik.isSubmitting || hasEmptyField || !formik.dirty}
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default RegisteredNumberTask;
