import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Box, Button } from 'elements';
import { TaskPropsBase } from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

interface Props {
  nextTask: TaskPropsBase['nextTask'];
  prevTask: TaskPropsBase['prevTask'];
  isTaskNavigationEnabled: boolean;
  disabled: boolean;
  onSuccess: () => void;
  successBtnText?: string;
}

const OnboardingTaskActions = ({
  nextTask,
  prevTask,
  isTaskNavigationEnabled,
  disabled,
  successBtnText,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { sectionKey } = useParams<{
    sectionKey: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();

  const prevTaskUrl = prevTask ? `/${prevTask.typeUrl}/${prevTask.id}` : '';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={4}
    >
      <Button
        variant="text"
        component={RouterLink}
        to={{
          pathname: generatePath(
            `${getPath('orgOnboarding')}/${sectionKey}${prevTaskUrl}`,
            {
              orgId: organization!.id,
            }
          ),
        }}
      >
        {t('common.button.back')}
      </Button>

      <Box sx={{ 'a + button': { ml: 2 } }}>
        {isTaskNavigationEnabled && nextTask && (
          <Button
            component={RouterLink}
            variant="text"
            to={{
              pathname: generatePath(
                `${getPath('orgOnboarding')}/${sectionKey}/${
                  nextTask.typeUrl
                }/${nextTask.id}`,
                {
                  orgId: organization!.id,
                }
              ),
            }}
          >
            {t('common.button.skip')}
          </Button>
        )}

        {canUser('org-onboarding-task:change') && (
          <Button disabled={disabled} onClick={onSuccess}>
            {successBtnText || t('common.button.continue')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingTaskActions;
