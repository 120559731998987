import { useCallback, useState } from 'react';
import { FileError, FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import {
  Alert,
  AlertTitle,
  Box,
  Dropzone,
  FileRejection,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  NetworkErrorCode,
  OnboardingDocumentFile,
  OnboardingItemStatus,
  OnboardingTaskBusinessEstDocs,
  OnboardingTaskType,
  TaskPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import formatBytes from 'services/utils/formatBytes';
import FileRow from './FileRow';

export interface Props extends TaskPropsBase {
  task: OnboardingTaskBusinessEstDocs;
  onUpdate: (newTask: OnboardingTaskBusinessEstDocs) => void;
  onInvalidate: () => Promise<void>;
}

interface State {
  acceptedFiles: FileWithPath[];
  fileRejections: FileRejection[];
  isLoading: boolean;
  uploadedFiles: OnboardingDocumentFile[];
  filePathUploadingProgressMap: { [key: string]: number };
}

const BusinessEstDocsTask = ({
  task,
  prevTask,
  nextTask,
  isTaskNavigationEnabled,
  onUpdate,
  onInvalidate,
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    data: {
      canBeCompleted: { value: taskCanBeCompleted },
      onboardingDocument: { value, editable: docEditable },
    },
  } = task;
  const docValue = value!;
  const [state, setState] = useState<State>({
    acceptedFiles: [],
    fileRejections: [],
    isLoading: false,
    uploadedFiles: docValue.files || [],
    filePathUploadingProgressMap: {},
  });

  const { useUpdateQA } = useTanstackQuery();
  const {
    mutate: orgUpdateQAMutate,
    isLoading: isUpdateQATaskLoading,
  } = useUpdateQA({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) =>
      setState((prevState) => {
        const alreadyAddedFilePaths = prevState.acceptedFiles.map(
          (file) => file.path
        );
        const newAcceptedFiles = acceptedFiles.filter(
          (file) => !alreadyAddedFilePaths.includes(file.path)
        );
        return {
          ...prevState,
          acceptedFiles: [...prevState.acceptedFiles, ...newAcceptedFiles],
          fileRejections,
        };
      }),
    []
  );

  const onSelectedFileRemove = (fileIndex: number) =>
    setState((prevState) => ({
      ...prevState,
      acceptedFiles: [
        ...prevState.acceptedFiles.slice(0, fileIndex),
        ...prevState.acceptedFiles.slice(fileIndex + 1),
      ],
    }));

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      let isFileTooLargeError = false;
      let tooManyFilesError = false;

      fileRejections.forEach((file) => {
        isFileTooLargeError = !!file.errors.find(
          (err) => err.code === 'file-too-large'
        );
        tooManyFilesError = !!file.errors.find(
          (err) => err.code === 'too-many-files'
        );
      });

      if (isFileTooLargeError) {
        enqueueSnackbar(
          t('dropzone.fileIsTooBigErrorMessage', {
            size: formatBytes(docValue.type.maxFileSizeInBytes),
          }),
          {
            variant: 'error',
          }
        );
        return;
      }
      if (tooManyFilesError) {
        enqueueSnackbar(t('dropzone.tooManyFilesErrorMessage'), {
          variant: 'error',
        });
        return;
      }
    },
    [enqueueSnackbar]
  );

  const onUpload = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    for (const currentFile of state.acceptedFiles) {
      try {
        const response = await api.uploadOnboardingDocumentFile(
          organization!.id,
          docValue.id,
          currentFile as File,
          (progress) => {
            if (!mounted.current) return;
            setState((prevState) => ({
              ...prevState,
              filePathUploadingProgressMap: {
                ...prevState.filePathUploadingProgressMap,
                [currentFile.path!]: progress,
              },
            }));
          }
        );
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          acceptedFiles: prevState.acceptedFiles.filter(
            (file) => file.path !== currentFile.path
          ),
          uploadedFiles: [...prevState.uploadedFiles, response],
        }));
      } catch (error) {
        const fileRejection: FileRejection = {
          file: currentFile as File,
          errors: [],
        };

        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.onboardingDocumentFileTooBigError
        ) {
          fileRejection.errors.push({
            message: '',
            code: 'file-too-large',
          });
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.onboardingDocumentFileTypeNotAllowedError
        ) {
          fileRejection.errors.push({
            message: '',
            code: 'file-invalid-type',
          });
        }
        if (!mounted.current) return;
        if (fileRejection.errors.length > 0) {
          setState((prevState) => ({
            ...prevState,
            acceptedFiles: prevState.acceptedFiles.filter(
              (file) => file.path !== currentFile.path
            ),
            fileRejections: [...prevState.fileRejections, fileRejection],
          }));
        } else {
          if (!mounted.current) return;
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    }

    await onInvalidate();

    if (!mounted.current) return;
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const onUploadedFileDelete = async (fileId: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.deleteOnboardingDocumentFile(
        organization!.id,
        docValue.id,
        fileId
      );

      await onInvalidate();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        uploadedFiles: prevState.uploadedFiles.filter(
          (file) => file.id !== fileId
        ),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const isUploadDisabled =
    docValue.type.maxNumberOfFiles <
      state.acceptedFiles.length + state.uploadedFiles.length ||
    docValue.type.minNumberOfFiles >
      state.acceptedFiles.length + state.uploadedFiles.length ||
    state.acceptedFiles.length === 0;

  const maxNumberOfFilesToUpload =
    docValue.type.maxNumberOfFiles -
    state.acceptedFiles.length -
    state.uploadedFiles.length;

  const taskCompleteMode =
    task.status !== OnboardingItemStatus.completed &&
    taskCanBeCompleted &&
    !state.acceptedFiles.length;
  const submitDisabled =
    isUpdateQATaskLoading ||
    state.isLoading ||
    (taskCompleteMode ? !taskCanBeCompleted : isUploadDisabled);
  console.log(submitDisabled, 123123);
  return (
    <>
      <PageTitle
        pt={0}
        title={t(
          `orgOnboardingTaskTitle.${OnboardingTaskType.businessEstablishmentDocs}`
        )}
      />
      <Typography variant="body2" color="textSecondary" mb={4}>
        {t('orgOnboardingBusinessEstDocsTask.description', {
          name: organization!.name,
        })}
      </Typography>

      {task.status === OnboardingItemStatus.submitted && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>
            {t('orgOnboardingTaskPage.verificationAlert.title')}
          </AlertTitle>
          {t('orgOnboardingTaskPage.verificationAlert.docDescription')}
        </Alert>
      )}

      {task.status === OnboardingItemStatus.requiresAction && task.comment && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          <AlertTitle>
            {t('orgOnboardingTaskPage.rejectedAlert.docTitle')}
          </AlertTitle>
          {task.comment}
        </Alert>
      )}

      <Dropzone
        file={null}
        isLoading={state.isLoading || isUpdateQATaskLoading}
        disabled={
          isUpdateQATaskLoading ||
          state.isLoading ||
          maxNumberOfFilesToUpload <= 0 ||
          !docEditable
        }
        onDrop={onDrop}
        onDropRejected={onDropRejected}
        dropzoneIdleProps={{
          description: t('orgOnboardingBusinessEstDocsTask.dropzoneLabel'),
        }}
        multiple
        accept={docValue.type.allowedMediaTypes}
        maxSize={docValue.type.maxFileSizeInBytes}
        maxFiles={maxNumberOfFilesToUpload}
        dataTestId="business-est-docs-dropzone"
      />

      <Box mt={3}>
        {state.uploadedFiles.map((file, index) => (
          <FileRow
            key={file.name + index}
            version="uploaded"
            file={file}
            disabled={!docEditable}
            isLoading={state.isLoading || isUpdateQATaskLoading}
            onDelete={() => onUploadedFileDelete(file.id)}
          />
        ))}

        {state.acceptedFiles.map((file, index) => (
          <FileRow
            key={file.path}
            version="selected"
            file={file}
            disabled={!docEditable}
            isLoading={state.isLoading || isUpdateQATaskLoading}
            uploadingProgress={state.filePathUploadingProgressMap[file.path!]}
            onDelete={() => onSelectedFileRemove(index)}
          />
        ))}

        {state.fileRejections.map(
          ({ file, errors }: { file: FileWithPath; errors: FileError[] }) => (
            <FileRow
              key={file.path}
              version="error"
              file={file}
              disabled={!docEditable}
              errors={errors}
              isLoading={state.isLoading || isUpdateQATaskLoading}
              maxFileSizeInBytes={docValue.type.maxFileSizeInBytes}
            />
          )
        )}
      </Box>

      <Box mt={4}>
        <OnboardingTaskActions
          prevTask={prevTask}
          nextTask={nextTask}
          isTaskNavigationEnabled={isTaskNavigationEnabled}
          disabled={submitDisabled}
          onSuccess={
            taskCompleteMode
              ? () =>
                  orgUpdateQAMutate({
                    organizationId: organization!.id,
                    taskId: task.id,
                  })
              : onUpload
          }
          successBtnText={
            taskCompleteMode
              ? undefined
              : t('orgOnboardingBusinessEstDocsTask.uploadBtn')
          }
        />
      </Box>

      <LoaderWithOverlay loading={state.isLoading || isUpdateQATaskLoading} />
    </>
  );
};

export default BusinessEstDocsTask;
