import {
  CalendarBlankIcon,
  CoinsIcon,
  CurrencyCircleDollarIcon,
  FilesIcon,
  IdentificationCardIcon,
  MapPinIcon,
  NumberCircleOneIcon,
  PlusCircleIcon,
  QuestionIcon,
  ReadCvLogoIcon,
  TrademarkRegisteredIcon,
  UsersIcon,
} from 'elements';
import { OnboardingSectionType, OnboardingTaskType } from 'services/constants';

export const SectionIconByType = {
  [OnboardingSectionType.companyOverview]: ReadCvLogoIcon,
  [OnboardingSectionType.companyDetails]: FilesIcon,
  [OnboardingSectionType.questionsAndAnswers]: PlusCircleIcon,
};

export const TaskIconByType = {
  [OnboardingTaskType.organizationName]: TrademarkRegisteredIcon,
  [OnboardingTaskType.registeredAddress]: MapPinIcon,
  [OnboardingTaskType.registeredNumber]: NumberCircleOneIcon,
  [OnboardingTaskType.foundationDate]: CalendarBlankIcon,
  [OnboardingTaskType.vatId]: IdentificationCardIcon,
  [OnboardingTaskType.annualRevenue]: CurrencyCircleDollarIcon,
  [OnboardingTaskType.balanceSheetTotal]: CoinsIcon,
  [OnboardingTaskType.staffHeadcount]: UsersIcon,
  [OnboardingTaskType.companyType]: QuestionIcon,
  [OnboardingTaskType.businessEstablishmentDocs]: FilesIcon,
};
