import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { SelectionPageToast } from 'layout';
import { getPath } from 'services/utils';

interface Props {
  count: number;
  totalCount: number;
  isDisabled: boolean;
  onSuccess: () => void;
}

const SectionFloatingToast = ({
  count,
  totalCount,
  isDisabled,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const history = useHistory();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  return (
    <>
      <SelectionPageToast
        width="lg"
        paperProps={{ elevation: 4 }}
        onSuccess={() => setIsConfirmDialogOpen(true)}
        onCancel={() =>
          history.push(
            generatePath(getPath('orgOnboarding'), {
              orgId: organization!.id,
            })
          )
        }
        confirmButtonProps={{
          children: t('orgOnboardingSectionPage.toast.submitBtn'),
          color: 'success',
          disabled: isDisabled,
        }}
        cancelButtonProps={{
          variant: 'outlined',
          children: t('orgOnboardingSectionPage.toast.backBtn'),
        }}
      >
        {t('orgOnboardingSectionPage.toast.completedCountText', {
          count,
          total: totalCount,
        })}
      </SelectionPageToast>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={t('orgOnboardingSectionPage.confirmDialog.title')}
        description={t('orgOnboardingSectionPage.confirmDialog.description')}
        confirmButtonProps={{
          children: t('common.button.confirm'),
        }}
        onSuccess={() => {
          setIsConfirmDialogOpen(false);
          onSuccess();
        }}
        onClose={() => setIsConfirmDialogOpen(false)}
      />
    </>
  );
};

export default SectionFloatingToast;
