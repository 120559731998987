import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CardPicker, DialogStepper } from 'domains/card/components';
import {
  FormValues,
  getInitialValues,
  Step,
} from 'domains/card/dialogs/CardRequestDialog';
import useStepManager from 'domains/card/dialogs/CardRequestDialog/useStepManager';
import MemberAutocomplete from 'domains/card/dialogs/IssueCardDialog/MemberAndTypeStep/MemberAutocomplete';
import {
  useAvailableCardConfigSettings,
  useAvailableVirtualTravelCardSubtypes,
} from 'domains/card/hooks';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useSnackbar from 'hooks/useSnackbar';
import { CustomFieldType } from 'services/constants';

interface Props {
  onClose: () => void;
}

const TypeStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdminApp } = useCurrentApp();
  const availableCardConfigSettings = useAvailableCardConfigSettings(
    !isAdminApp
  );
  const availableTravelCardSubtypes = useAvailableVirtualTravelCardSubtypes();
  const formik = useFormikContext<FormValues>();
  const stepManager = useStepManager();

  useEffect(() => {
    if (!availableCardConfigSettings.length)
      enqueueSnackbar(t('errors.general'), { variant: 'error' });
  }, [availableCardConfigSettings.length]);

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>
        {isAdminApp
          ? t('issueCardDialog_v2.memberAndTypeStep.title')
          : t('cardRequestDialog_v2.typeStep.title')}
      </DialogTitle>
      <DialogContent>
        {isAdminApp && (
          <Box mb={3}>
            <MemberAutocomplete
              value={formik.values.member}
              onChange={(member) =>
                formik.resetForm({
                  values: getInitialValues({
                    member,
                    customFields: formik.values.customFieldsResponse.map(
                      (item) => item.defaultValue || ''
                    ),
                    customFieldsResponse: formik.values.customFieldsResponse,
                  }),
                })
              }
            />
          </Box>
        )}
        <Typography component="div" variant="caption" mb={3}>
          {t('cardRequestDialog_v2.typeStep.chooseCardType')}
        </Typography>
        <CardPicker
          items={availableCardConfigSettings}
          travelCardSubtypes={availableTravelCardSubtypes}
          value={formik.values.cardConfigSetting}
          onChange={(cardConfigSetting) =>
            formik.resetForm({
              values: getInitialValues({
                member: formik.values.member,
                cardConfigSetting,
                customFields: formik.values.customFieldsResponse.map(
                  (customField) => {
                    if (customField.type === CustomFieldType.select)
                      return customField.defaultOption;
                    return customField.defaultValue || '';
                  }
                ),
                customFieldsResponse: formik.values.customFieldsResponse,
              }),
            })
          }
          memberId={formik.values.member?.id || null}
          isCardRequestFlow={true}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!formik.values.member || !formik.values.cardConfigSetting}
          onClick={stepManager.goNext}
        >
          {t('common.button.continue')}
        </Button>
      </DialogActions>
    </>
  );
};

export default TypeStep;
