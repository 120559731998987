import { useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import { sortLabelsByAmounts } from 'domains/onboarding/utils';
import {
  Box,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  AnnualRevenue,
  OnboardingTaskAnnualRevenue,
  OnboardingTaskType,
  TaskPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  annualRevenue: AnnualRevenue | '';
}

export interface Props extends TaskPropsBase {
  task: OnboardingTaskAnnualRevenue;
  onUpdate: (newTask: OnboardingTaskAnnualRevenue) => void;
}

const AnnualRevenueTask = ({
  task,
  prevTask,
  nextTask,
  isTaskNavigationEnabled,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    data: { annualRevenue },
  } = task;
  const { useUpdateAnnualRevenue } = useTanstackQuery();
  const { mutate: orgAnnualRevenueMutate } = useUpdateAnnualRevenue({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      annualRevenue: annualRevenue.value ?? '',
    },
    onSubmit: (values) => {
      if (!values.annualRevenue) return;
      orgAnnualRevenueMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: {
          annualRevenue: values.annualRevenue,
        },
      });
    },
  });

  const sortedAnnualRevenueValues = useMemo(
    () =>
      sortLabelsByAmounts(
        annualRevenue.possibleValues.map((item) => ({
          value: item,
          label: t(`annualRevenueLabels.${item}`),
        }))
      ),
    [annualRevenue.possibleValues]
  );

  return (
    <>
      <PageTitle
        pt={0}
        title={t(`orgOnboardingTaskTitle.${OnboardingTaskType.annualRevenue}`)}
      />
      <Typography variant="body2" mb={5}>
        {t('orgOnboardingAnnualRevenueTask.description', {
          year: moment().year() - 1,
        })}
      </Typography>

      <FormControl
        fullWidth
        disabled={formik.isSubmitting || !annualRevenue.editable}
      >
        <InputLabel id="annual-revenue">
          {t('orgOnboardingAnnualRevenueTask.annualRevenueLabel')}
        </InputLabel>
        <Select
          displayEmpty
          labelId="annual-revenue"
          renderValue={(selected) => {
            if (!selected) return '';
            return t(`annualRevenueLabels.${selected}`);
          }}
          {...formik.getFieldProps('annualRevenue')}
        >
          {sortedAnnualRevenueValues.map((revenue) => (
            <MenuItem key={revenue.label} value={revenue.value}>
              {revenue.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box mt={4}>
        <OnboardingTaskActions
          prevTask={prevTask}
          nextTask={nextTask}
          isTaskNavigationEnabled={isTaskNavigationEnabled}
          disabled={
            formik.isSubmitting || !formik.dirty || !formik.values.annualRevenue
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default AnnualRevenueTask;
