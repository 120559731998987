import { useState } from 'react';
import useMounted from 'hooks/useMounted';

export const getReadableRemainingTime = (remainingTime: number) => {
  let minutes = Math.floor(remainingTime / 60);
  let seconds = remainingTime % 60;

  return `${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
};

// Note that remainingTime and duration are in seconds
const useCountdownTimer = () => {
  const mounted = useMounted();
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  const startTimer = (duration: number) => {
    setRemainingTime(duration);

    let intervalId = setInterval(() => {
      if (!mounted.current) {
        clearInterval(intervalId);
        return;
      }
      setRemainingTime((prevTime) => {
        const currentTime = prevTime! - 1;
        if (currentTime < 0) {
          clearInterval(intervalId);
          return null;
        } else {
          return currentTime;
        }
      });
    }, 1000);
  };

  return {
    remainingTime,
    startTimer,
  };
};

export default useCountdownTimer;
