import { useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingBreadcrumbs } from 'domains/onboarding/components';
import {
  getOnboardingKeyTypeFromUrl,
  getTaskNavigationLinks,
} from 'domains/onboarding/utils';
import {
  Box,
  LoaderWithOverlay,
  MobileStepperProgress,
  Typography,
} from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import { ContentContainer, PageContent, PageHeader } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingSections,
  OnboardingSectionType,
  OnboardingTask,
  OnboardingTaskAnnualRevenue,
  OnboardingTaskBalanceSheetTotal,
  OnboardingTaskBusinessEstDocs,
  OnboardingTaskCompanyType,
  OnboardingTaskFoundationDate,
  OnboardingTaskOrgName,
  OnboardingTaskRegAddress,
  OnboardingTaskRegNumber,
  OnboardingTasks,
  OnboardingTaskStaffHeadcount,
  OnboardingTaskType,
  OnboardingTaskVatId,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getPath } from 'services/utils';
import AnnualRevenueTask from './AnnualRevenueTask';
import BalanceSheetTotalTask from './BalanceSheetTotalTask';
import BusinessEstDocsTask from './BusinessEstDocsTask';
import CompanyTypeTask from './CompanyTypeTask';
import FoundationDateTask from './FoundationDateTask';
import RegisteredAddessTask from './RegisteredAddessTask';
import RegisteredNumberTask from './RegisteredNumberTask';
import StaffHeadcountTask from './StaffHeadcountTask';
import TradeNameTask from './TradeNameTask';
import VatIdTask from './VatIdTask';

const OrganizationOnboardingTaskPage = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const showPageError = useShowPageError();
  const queryClient = useQueryClient();
  const { useGetOnboardingTasks } = useTanstackQuery();

  const { sectionKey, taskKey, taskId } = useParams<{
    sectionKey: string;
    taskKey: string;
    taskId: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const currentTaskType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingTaskType>(taskKey),
    [taskKey]
  );

  const { data: tasksData, isFetching, error } = useGetOnboardingTasks(
    organization!.id,
    currentSectionType
  );

  const tasks = tasksData?.tasks || [];
  const numberCompletedTasks = useMemo(
    () =>
      tasks.filter((task) => task.status === OnboardingItemStatus.completed)
        .length,
    [tasks]
  );
  const shownTask = useMemo(
    () => tasks.find((task) => task.id === taskId) || null,
    [tasks, taskId]
  );

  const { prevTask, nextTask, isTaskNavigationEnabled } = useMemo(
    () => getTaskNavigationLinks(tasks, shownTask?.id),
    [tasks, shownTask]
  );

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [history.location.pathname]);

  useEffect(() => {
    if (error) {
      logError(error);
      showPageError();
    }
  }, [error]);

  // use when refetched data is important for the next interactions
  const onTaskInvalidate = async () => {
    await queryClient.invalidateQueries(
      onboardingKeys.tasks(organization!.id, currentSectionType)
    );
    await queryClient.invalidateQueries(
      onboardingKeys.sections(organization!.id)
    );
  };

  const onTaskUpdate = async (newTask: OnboardingTask) => {
    // update tasks and sections so that all tasks have up-to-date info
    await queryClient.invalidateQueries(
      onboardingKeys.tasks(organization!.id, currentSectionType)
    );
    await queryClient.invalidateQueries(
      onboardingKeys.sections(organization!.id)
    );

    // override data with optimistic response in case when BE
    // returned outdated data
    queryClient.setQueryData<OnboardingTasks>(
      onboardingKeys.tasks(organization!.id, currentSectionType),
      (prevState) => ({
        tasks:
          prevState?.tasks.map((task) =>
            task.id === newTask.id ? newTask : task
          ) || [],
      })
    );
    queryClient.setQueryData<OnboardingSections>(
      onboardingKeys.sections(organization!.id),
      (prevState) => {
        const { data, ...newSectionTask } = newTask;
        return {
          sections:
            prevState?.sections.map((section) => ({
              ...section,
              tasks:
                section.tasks.map((task) =>
                  task.id === newSectionTask.id ? newSectionTask : task
                ) || [],
            })) || [],
        };
      }
    );

    const redirectUrl = generatePath(
      nextTask
        ? `${getPath('orgOnboarding')}/${sectionKey}/${nextTask.typeUrl}/${
            nextTask.id
          }`
        : `${getPath('orgOnboarding')}/${sectionKey}`,
      {
        orgId: organization!.id,
      }
    );
    history.push(redirectUrl);
  };

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs />

          <Box mt={5}>
            <Typography variant="subtitle2">
              {t('orgOnboardingTaskPage.stepper', {
                completed: numberCompletedTasks,
                total: tasks.length,
              })}
            </Typography>

            <MobileStepperProgress
              steps={tasks.length}
              activeStep={numberCompletedTasks - 1}
              nextButton={null}
              backButton={null}
              sx={{
                px: 0,
                '.MuiMobileStepper-progress': {
                  width: '100%',
                },
              }}
            />
          </Box>
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column" ref={contentRef}>
        <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
          {shownTask &&
            {
              [OnboardingTaskType.organizationName]: (
                <TradeNameTask
                  task={shownTask as OnboardingTaskOrgName}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.registeredAddress]: (
                <RegisteredAddessTask
                  task={shownTask as OnboardingTaskRegAddress}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.registeredNumber]: (
                <RegisteredNumberTask
                  task={shownTask as OnboardingTaskRegNumber}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.foundationDate]: (
                <FoundationDateTask
                  task={shownTask as OnboardingTaskFoundationDate}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.vatId]: (
                <VatIdTask
                  task={shownTask as OnboardingTaskVatId}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.annualRevenue]: (
                <AnnualRevenueTask
                  task={shownTask as OnboardingTaskAnnualRevenue}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.balanceSheetTotal]: (
                <BalanceSheetTotalTask
                  task={shownTask as OnboardingTaskBalanceSheetTotal}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.staffHeadcount]: (
                <StaffHeadcountTask
                  task={shownTask as OnboardingTaskStaffHeadcount}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.companyType]: (
                <CompanyTypeTask
                  task={shownTask as OnboardingTaskCompanyType}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                />
              ),
              [OnboardingTaskType.businessEstablishmentDocs]: (
                <BusinessEstDocsTask
                  key={shownTask.id}
                  task={shownTask as OnboardingTaskBusinessEstDocs}
                  prevTask={prevTask}
                  nextTask={nextTask}
                  isTaskNavigationEnabled={isTaskNavigationEnabled}
                  onUpdate={onTaskUpdate}
                  onInvalidate={onTaskInvalidate}
                />
              ),
            }[currentTaskType]}

          {!shownTask && !isFetching && <NothingFound />}
        </ContentContainer>

        <LoaderWithOverlay loading={isFetching} />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingTaskPage;
