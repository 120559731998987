import { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmPaymentWith3dsBiometricAuthDialog } from 'domains/card/dialogs';
import useSnackbar from 'hooks/useSnackbar';
import {
  BiometricAuthRequestEventData,
  BiometricAuthResponseEventData,
  useMemberSse,
} from 'services/memberSse';

const with3dsBiometricAuthentication = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { memberSse } = useMemberSse();
  const [
    authRequest,
    setAuthRequest,
  ] = useState<BiometricAuthRequestEventData | null>(null);

  useEffect(() => {
    const onBiometricAuthRequest = (
      e: CustomEvent<BiometricAuthRequestEventData>
    ) => {
      setAuthRequest(e.detail);
    };

    const onBiometricAuthResponse = (
      e: CustomEvent<BiometricAuthResponseEventData>
    ) => {
      setAuthRequest(null);
      enqueueSnackbar(t(`3dsBiometricAuthSuccessMessages.${e.detail.result}`), {
        variant: e.detail.result === 'APPROVED' ? 'default' : 'error',
      });
    };

    memberSse.addEventListener(
      'BIOMETRIC_AUTH_REQUEST',
      onBiometricAuthRequest
    );
    memberSse.addEventListener(
      'BIOMETRIC_AUTH_RESPONSE',
      onBiometricAuthResponse
    );

    return () => {
      memberSse.removeEventListener(
        'BIOMETRIC_AUTH_REQUEST',
        onBiometricAuthRequest
      );
      memberSse.removeEventListener(
        'BIOMETRIC_AUTH_RESPONSE',
        onBiometricAuthResponse
      );
    };
  }, []);

  return (
    <>
      <Component {...props} />

      <ConfirmPaymentWith3dsBiometricAuthDialog
        open={!!authRequest}
        authRequest={authRequest}
        onClose={() => setAuthRequest(null)}
      />
    </>
  );
};

export default with3dsBiometricAuthentication;
