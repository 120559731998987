import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingBreadcrumbs,
  TaskListItem,
} from 'domains/onboarding/components';
import { getOnboardingKeyTypeFromUrl } from 'domains/onboarding/utils';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import { List, LoaderWithOverlay, Typography } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import SectionFloatingToast from './SectionFloatingToast';

const OrganizationOnboardingSectionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const canUser = useCanUser();
  const { sectionKey } = useParams<{ sectionKey: string }>();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    state: { organization },
  } = useGlobalState();
  const showPageError = useShowPageError();
  const {
    useGetOnboardingSections,
    useGetOnboardingTasks,
    useSubmitOnboardingSection,
  } = useTanstackQuery();
  const {
    data: sectionsData,
    isFetching: areSectionsLoading,
  } = useGetOnboardingSections(organization!.id);
  const {
    data: tasksData,
    isFetching: areTasksLoading,
    error: getTasksError,
  } = useGetOnboardingTasks(organization!.id, currentSectionType);

  const tasks = tasksData?.tasks || [];
  const sections = sectionsData?.sections || [];
  const currentSectionObject = sections.find(
    (item) => item.type === currentSectionType
  );
  const isFloatingToastVisible =
    !areTasksLoading &&
    !areSectionsLoading &&
    !!currentSectionObject?.status &&
    [
      OnboardingItemStatus.completed,
      OnboardingItemStatus.requiresAction,
    ].includes(currentSectionObject.status);

  const {
    mutate: orgFoundationDateMutate,
    isLoading: isSubmitting,
  } = useSubmitOnboardingSection({
    onSuccess: () => {
      queryClient.invalidateQueries(
        onboardingKeys.tasks(organization!.id, currentSectionType)
      );
      queryClient.invalidateQueries(onboardingKeys.sections(organization!.id));
      if (!mounted.current) return;
      history.push(
        generatePath(getPath('orgOnboarding'), {
          orgId: organization!.id,
        })
      );
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  useEffect(() => {
    if (getTasksError) {
      logError(getTasksError);
      showPageError();
    }
  }, [getTasksError]);

  const numberCompletedTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.status === OnboardingItemStatus.completed ||
          task.status === OnboardingItemStatus.submitted
      ).length,
    [tasks]
  );

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs />

          <PageTitle
            title={t(`orgOnboardingSectionTitle.${currentSectionType}`)}
          />

          <Typography mt={1} variant="body2" color="textSecondary">
            {t(`orgOnboardingSectionPage.description.${currentSectionType}`)}
          </Typography>
        </ContentContainer>
      </PageHeader>

      <PageContent
        display="flex"
        flexDirection="column"
        pb={isFloatingToastVisible ? 20 : 0}
        position="static"
      >
        {!!tasks.length && (
          <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
            <List>
              {tasks.map((task) => (
                <TaskListItem
                  key={task.id}
                  task={task}
                  sectionKey={sectionKey}
                />
              ))}
            </List>
          </ContentContainer>
        )}

        {!areTasksLoading && tasks.length === 0 && (
          <ContentContainer
            mx="auto"
            size="sm"
            width="100%"
            flex={1}
            position="relative"
          >
            <NothingFound />
          </ContentContainer>
        )}

        <PartnerLegalDisclaimer />

        {isFloatingToastVisible && (
          <SectionFloatingToast
            count={numberCompletedTasks}
            totalCount={tasks.length}
            isDisabled={
              !canUser('org-onboarding-section:submit') ||
              tasks.length !== numberCompletedTasks ||
              isSubmitting
            }
            onSuccess={() =>
              orgFoundationDateMutate({
                organizationId: organization!.id,
                type: currentSectionType,
              })
            }
          />
        )}

        <LoaderWithOverlay
          loading={areTasksLoading || areSectionsLoading || isSubmitting}
        />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingSectionPage;
